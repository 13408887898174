
.order-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .answer{
        display: flex;
        align-items: center;
        img{
            width: 80px;
            height: 80px;
        }
        .text{
            padding-left: 12px;
        }
    }
    .notes-title{
        color: #999;
        padding: 0 100px;
    }
    .dialog-footer{
        margin-top: 20px;
        text-align: center;
    }
}

